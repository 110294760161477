import {
  Login,
  Register,
  Lite,
  ForgotPassword,
  SetPassword,
  Home,
  Applications,
  Reports,
  Search,
  Profile,
  DisbursementReports,
  Administrator,
  LeadsWelcome,
  LeadsContent,
  Risk,
  PartnerProfile,
  Metrics,
  Integrations,
  Digest,
  Admissions,
  Permissions,
} from 'constants/copy/pages'
import { APP_NAME } from 'constants/countryInfo'
import { REACT_APP_MAXIMUM_PARTNER_USER } from 'env'

export const LOGIN_COPY: CopyRecord<Login> = {
  [Login.heading]: 'Masuk ke mitra dashboard',
  [Login.subheading]:
    'Silakan masukkan email Anda yang sudah terdaftar dan kata sandi untuk masuk ke akun Anda.',
  [Login.alert]: `Sesi Anda telah berakhir, untuk alasan keamanan Anda harus masuk kembali dengan mengisi halaman ini.`,
}

export const REGISTER_COPY: CopyRecord<Register> = {
  [Register.heading]: `Selesaikan pendaftaran Anda di ${APP_NAME}`,
  [Register.subheading]: 'Amankan akun Anda dengan membuat kata sandi.',
  [Register.agreement]: `Saya telah membaca dan menyetujui <0>{{termsAndConditions}}</0> dan <1>{{privacyPolicy}}</1> ${APP_NAME}.`,
  [Register.termsAndConditions]: 'Syarat dan Ketentuan',
  [Register.privacyPolicy]: 'Kebijakan Privasi',
}

export const FORGOT_PASSWORD_COPY: CopyRecord<ForgotPassword> = {
  [ForgotPassword.heading]: 'Lupa kata sandi?',
  [ForgotPassword.subheading]:
    'Mohon masukkan email terdaftar untuk melanjutkan.',
  [ForgotPassword.success]:
    'Email sudah terkirim, silakan cek email untuk mengubah kata sandi.',
}

export const SET_PASSWORD_COPY: CopyRecord<SetPassword> = {
  [SetPassword.heading]: 'Atur kata sandi',
  [SetPassword.subheading]: 'Masukan kata sandi baru',
}

export const HOME_COPY: CopyRecord<Home> = {
  [Home.title]: 'Dasbor',
  [Home.greeting]: `Hi`,
  [Home.news]: `Cek halaman ini untuk mengetahui fitur dan aktivasi pembiayaan terbaru.`,
  [Home.widgetInfo]:
    'Total dana yang dicairkan ke Institusi Anda sejak bermitra.',
  [Home.widgetInfoStudent]:
    'Total pelajar yang telah mendapat pembiayaan dari Danacita sejak bermitra.',
  [Home.widgetTitleDisbursement]: 'Total Pencairan Dana',
  [Home.widgetTitleStudent]: 'Total Pelajar',
  [Home.quickLink]: `Quick Links`,
  [Home.quickLinkSubtitle]: `Shortcut untuk mengakses informasi penting.`,
  [Home.quickLinkBlankTitle]: `Tidak ada quick links saat ini`,
  [Home.quickLinkBlankSubtitle]: `Cek kembali nanti untuk mengakses informasi penting seperti laporan bulanan dan review akhir tahun dengan cepat di sini.`,
  [Home.welcome]: `Selamat datang di ${APP_NAME} dashboard Anda`,
  [Home.chooseLanguage]: `Pilih Bahasa :`,
  [Home.applications]: 'Aktivasi Pembiayaan',
  [Home.applicationsSubheading]: 'Ringkasan aktivasi pembiayaan baru',
  [Home.reports]: 'Laporan',
  [Home.reportsSubheading]: 'Ringkasan pencairan dana terakhir',

  [Home.onboardingHeading]: 'Selamat datang di Partner Dashboard',
  [Home.onboardingDescription]: `Dashboard ini adalah tempat bagi Anda untuk melacak aplikasi, mengirim pemberitahuan, mengakses laporan, membuat leads, dan melengkapi profil Anda.`,
  [Home.onboardingTrack]: 'Lihat aplikasi dan kirim notifikasi',
  [Home.onboardingTrackDescription]:
    'Lihat aplikasi secara real-time. Kirim pemberitahuan ke pelajar Anda aktif untuk memberi tahu mereka bahwa Anda telah menerima konfirmasi rencana pembiayaan mereka.',
  [Home.onboardingDownloadReports]: 'Download laporan',
  [Home.onboardingDownloadReportsDescription]:
    'Unduh laporan pencairan langsung dari dashboard Anda. Anda juga dapat mengirim pemberitahuan secara masal ke semua pelajar yang baru aktif.',
  [Home.onboardingLeads]: 'Membuat Leads',
  [Home.onboardingLeadsDescription]:
    'Tingkatkan pelajar yang ingin mendaftar disekolah Anda dengan merekomendasikan Danacita menggunakan fitur leads dan informasi akan terhubung langsung dengan mereka.',

  [Home.partnershipSurveyHeading]: 'Partnership Survey',
  [Home.partnershipSurveyDescription]:
    'Hello! We just wanted to check up on you and get feedback on what you think about our partnership and your dashboard. Please take 5 minutes to answer our survey.',

  [Home.restrictedAccessTitle]: 'Akses Halaman Ini Dibatasi',
  [Home.restrictedAccessSubtitle]: `Mohon untuk berkoordinasi dengan manajer Anda, agar akun Anda dapat mengakses halaman ini.`,

  [Home.emptyActivation]: ' Tidak ada aktivasi untuk hari ini.',
  [Home.emptyActivationButtonText]: 'Tambahkan Leads',
  [Home.emptyActivationSubtitle]: `Cek kembali besok. Untuk saat ini, Anda dapat merekomendasikan pelajar yang membutuhkan pembiayaan bulanan Danacita dengan menambahkan Leads.`,
  [Home.emptyActivationAddLeads]: `Dan Anda juga bisa menambahkan lead untuk meningkatkan aktivasi.`,
}

export const APPLICATIONS_COPY: CopyRecord<Applications> = {
  [Applications.title]: 'Aplikasi',
  [Applications.tabAll]: 'Semua Aplikasi',
  [Applications.tabReportGenerated]: 'Laporan Dibuat',
  [Applications.tabReportReceived]: 'Laporan Diterima',
  [Applications.revisionOverviewBadge]: 'MENUNGGU REVISI PELAJAR',
  [Applications.revisionOverviewTitle]: 'Informasi/Dokumen Tidak Lengkap',
  [Applications.revisionOverviewSubtitle]:
    'Pelajar perlu merevisi dan melengkapi hal-hal di bawah ini:',

  [Applications.headingName]: 'Nama (Belakang, Depan)',
  [Applications.headingMobileNumber]: 'Nomor HP',
  [Applications.headingEmail]: 'Email',
  [Applications.headingBeneficiary]: 'Nama Pelajar (Anak)',
  [Applications.headingSchool]: 'Lembaga Pendidikan',
  [Applications.headingIdNum]: 'ID Pelajar',
  [Applications.headingDegree]: 'Gelar',
  [Applications.headingProgram]: 'Program Studi',
  [Applications.headingDateSubmitted]: 'Tanggal Submission',
  [Applications.headingDateActivated]: 'Tanggal Aktivasi Pembiayaan',
  [Applications.headingCoborrower]: 'Co-Borrower',
  [Applications.headingDisbursedAmount]: 'Jumlah yang Dicairkan',
  [Applications.headingApprovedAmount]: 'Jumlah yang Disetujui',
  [Applications.headingRequestedAmount]: 'Jumlah yang Diajukan',
  [Applications.headingRefundAmount]: 'Jumlah yang Dikembalikan',
  [Applications.headingAmount]: 'Jumlah',
  [Applications.headingReference]: 'Kode Referensi Aplikasi',
  [Applications.headingLoanStatus]: 'Status Pengajuan',
  [Applications.headingLoanProduct]: 'Skema Cicilan',
  [Applications.headingReportStatus]: 'Status Notifikasi',
  [Applications.headingAction]: 'Informasi Lengkap Aplikasi',
  [Applications.filterDateActivated]: 'Periode Aplikasi',
  [Applications.filterCourse]: 'Program',
  [Applications.filterLoanStatus]: 'Status Aplikasi',
  [Applications.filterPartnerName]: 'Sekolah',

  [Applications.pendingReportStatus]: '-',
  [Applications.newReportStatus]: 'Baru',
  [Applications.postedReportStatus]: 'Notifikasi terkirim',

  [Applications.degreeAll]: 'Lihat semua gelar',

  [Applications.degreeBS]: '',
  [Applications.degreeBA]: '',
  [Applications.degreeB]: '',
  [Applications.degreeBFA]: '',
  [Applications.degreeMS]: '',
  [Applications.degreeMA]: '',
  [Applications.degreeM]: '',
  [Applications.degreeD]: '',
  [Applications.degreePhD]: '',
  [Applications.degreeMD]: '',
  [Applications.degreeJD]: '',
  [Applications.degreeShort]: '',

  [Applications.allDays]: 'Setiap Hari',
  [Applications.day]: 'Hari',
  [Applications.days]: 'Hari',
  [Applications.applyFilter]: 'Terapkan Filter',
  [Applications.filterBy]: 'Filter',
  [Applications.sendNotification]: 'Kirim Notifikasi',
  [Applications.reset]: 'Reset',

  [Applications.degreeD1]: 'D1',
  [Applications.degreeD2]: 'D2',
  [Applications.degreeD3]: 'D3',
  [Applications.degreeS1]: 'S1',
  [Applications.degreeS2]: 'S2',
  [Applications.degreeS3]: 'S3',

  [Applications.postSuccess]: 'Notifikasi berhasil dikirim!',
  [Applications.postSuccessMessage]: `Kami telah mengirim email kepada pelajar untuk menginformasikan bahwa pembiayaan telah dilakukan.`,
  [Applications.postErrorMessage]: 'Gagal. Coba lagi',
  [Applications.overviewTitle]: 'Ringkasan Aplikasi',
  [Applications.labelPayment]: 'Pembayaran bulanan',
  [Applications.labelServiceFee]: 'One-time service fee',
  [Applications.labelRequestedAmount]: 'Jumlah Total yang Diajukan',
  [Applications.labelLoanDuration]: 'Durasi pembiayaan',
  [Applications.labelInterest]: 'Biaya platform diterapkan per bulan',
  [Applications.labelTotalAmount]: 'Jumlah total termasuk biaya platform',
  [Applications.tenor]: '{{tenor}} bulan',

  [Applications.VAUpdateTitle]: 'Virtual Account Pelajar',
  [Applications.needUpdating]: 'Ada {{number}} Nomor VA yang perlu diisi.',
  [Applications.studentID]: 'NIM: {{id}}',
  [Applications.approvedPrincipal]: 'Nominal Disetujui: {{amount}}',
  [Applications.bankSelectionSectionTitle]: 'Nama Bank',
  [Applications.inputVASectionTitle]: 'Nomor Virtual Account Pelajar',
  [Applications.VAHelper]: 'VA hanya terdiri dari angka saja',
  [Applications.modalsubmitTitle]: 'Perbarui Nomor Virtual Account',
  [Applications.modalsubmitInfo]:
    'Pastikan lagi nomor virtual account yang Anda masukan sudah benar.',
  [Applications.bankNamePlaceholder]: 'Isi Nama Bank',
  [Applications.VAPlaceholder]: 'Masukan Nomor VA',

  [Applications.customView]: 'Personalisasi Tampilan',
  [Applications.customViewPopoverSubheading]:
    'Lakukan pencarian lebih cepat dan mudah tanpa perlu mengatur ulang setiap kali ingin mencari data dengan filter yang sama.',
  [Applications.customViewEmpty]: 'Personalisasi Tampilan belum ditambahkan.',
  [Applications.customViewCreate]: 'Tambahkan Sekarang',
  [Applications.customViewSelect]: 'Pilih Personalisasi Tampilan',
  [Applications.customViewSelectPlaceholder]: 'Piliih salah satu',
  [Applications.customViewModalMainHeading]: 'Apa itu Personalisasi Tampilan?',
  [Applications.customViewModalMainSubheading]:
    'Personalisasi Tampilan memudahkan Anda mendapatkan data lebih cepat dan mudah, dengan menggunakan filter atau pencarian yang telah disimpan.',
  [Applications.customViewTitleInputLabel]: 'Nama Personalisasi Tampilan',
  [Applications.customViewTitleInputPlaceholder]:
    'Contoh: Laporan Pengajuan Bulanan',
  [Applications.customViewAccessInputLabel]: 'Siapa yang Dapat Mengakses?',
  [Applications.customViewAccessOptionTeam]: 'Semua Anggota Tim',
  [Applications.customViewAccessOptionSelf]: 'Hanya Saya',
  [Applications.customViewActionCancel]: 'Batal',
  [Applications.customViewActionCreate]: 'Tambahkan',
  [Applications.customViewActionSaveAsNew]:
    'Simpan sebagai Personalisasi Tampilan Baru',
  [Applications.customViewActionOverwrite]: 'Ubah Informasi',
  [Applications.customViewActionOverwriteConfirm]: 'Ubah Informasi?',
  [Applications.customViewOverwriteConfirmDescription]:
    'Dengan klik “Ubah Informasi” artinya Anda akan mengubah setelan Personalisasi Tampilan untuk',
  [Applications.customViewActionDelete]: 'Hapus Personalisasi Tampilan',
  [Applications.customViewActionAddNew]: 'Tambahkan ke Personalisasi Tampilan',
  [Applications.customViewConfirmChange]: 'Apakah Anda ingin mengubahnya?',

  [Applications.overviewStudentFullName]: 'Nama',
  [Applications.overviewStudentDetails]: 'Informasi Pelajar',
  [Applications.overviewStudentId]: 'ID Pelajar',
  [Applications.overviewStudentDegree]: 'Program',
  [Applications.overviewStudentProgram]: 'Program Studi',
  [Applications.overviewGuardian]: 'Parent/Guardian',
  [Applications.overviewLoanDetails]: 'Informasi Aplikasi',
  [Applications.overviewLoanProduct]: 'Skema Cicilan',
  [Applications.overviewApplicationSubmitted]: 'Tanggal Submission',
  [Applications.overviewTotalReqAmount]: 'Jumlah yang Diajukan',
  [Applications.overviewApprovedAmount]: 'Jumlah yang Disetujui',
  [Applications.overviewDisbursedAmount]: 'Jumlah yang Dicairkan',
  [Applications.overviewDisbursedDate]: 'Tanggal Aktivasi Pembiayaan',
  [Applications.overviewRejectionNote]: 'Catatan',
  [Applications.overviewMonthlyPayment]: 'Monthly payment',
  [Applications.overviewLoanDuration]: 'Loan duration',
  [Applications.overviewServiceFee]: 'One-time service fee',
  [Applications.overviewTotalAmount]: 'Total amount including interest',
  [Applications.overviewMonthlyInterest]: 'Interest applied per month',
  [Applications.overviewIncompleteDocuments]: 'Informasi/Dokumen Tidak Lengkap',
  [Applications.overviewIncompleteDocumentsSubheading]:
    'Pelajar perlu merevisi dan melengkapi hal-hal di bawah ini:',
  [Applications.overviewEditConfirmation]: 'Konfirmasi',
  [Applications.overviewEditConfirmationContent]:
    'Dengan melanjutkan, Anda akan mengubah program studi pelajar. Apakah Anda yakin?',
}

export const REPORTS_COPY: CopyRecord<Reports> = {
  [Reports.title]: 'Laporan',
  [Reports.subheading]:
    'Pantau kinerja pembiayaan sekolah Anda yang telah aktif secara real-time',
  [Reports.reportSectionTitle]: 'Rangkuman',
  [Reports.filterDegree]: 'Berdasarkan Strata',
  [Reports.filterYear]: 'Berdasarkan Tahun',
  [Reports.degree]: 'Strata',
  [Reports.year]: 'Tahun',
  [Reports.byNumber]: 'Jumlah Pelajar Aktif (#)',
  [Reports.byAmount]: 'Total Pembiayaan (Rp)',
}

export const DISBURESMENT_REPORTS_COPY: CopyRecord<DisbursementReports> = {
  [DisbursementReports.title]: 'Laporan',
  [DisbursementReports.subheading]:
    'Tetap lacak performa sekolah Anda dengan update terbaru.',
  [DisbursementReports.partnerName]: 'Nama',
  [DisbursementReports.bankReferenceCode]: 'Kode Transfer',
  [DisbursementReports.dateDisbursement]: 'Tanggal Pencairan',
  [DisbursementReports.numberOfActivation]: 'Jumlah Pembiayaan',
  [DisbursementReports.disbursedAmount]: 'Jumlah Dana yang dapat Dicairkan',
  [DisbursementReports.totalRefundAmount]: 'Jumlah Dana yang Dikembalikan',
  [DisbursementReports.actualFundAmountDisbursed]:
    'Jumlah Dana Aktual yang Dicairkan',
  [DisbursementReports.modalTitle]: 'Perhatian',
  [DisbursementReports.modalAlertSafari]: `Anda menggunakan Safari. Untuk mengunduh file kedalam perangkat Anda, silahkan klik <0>command + shift + s</0> dan pilih <1>Page Source</1> sebagai pilihan format.`,
  [DisbursementReports.modalContent]: `Dengan mengunduh laporan pembiayaan, secara otomatis akan mengirimkan email notifikasi kepada pelajar tentang pembiayaan yang sudah dilakukan.`,
  [DisbursementReports.modalContentDowloaded]: `Anda akan mendownload laporan ini kedalam perangkat Anda.`,
  [DisbursementReports.modalAction]: 'Download Laporan',
  [DisbursementReports.modalActionWithSendNotif]:
    'Download Laporan & Kirim Notifikasi',
  [DisbursementReports.postSuccess]: 'Laporan diterima!',
  [DisbursementReports.postSuccessMessage]: `Kami telah mengirim email kepada pelajar untuk memberitahu bahwa pembiayaan akan terlihat disistem internal sekolah pelajar.`,
}

export const SEARCH_COPY: CopyRecord<Search> = {
  [Search.title]: 'Hasil Pencarian',
  [Search.subheading]: 'Menampilkan hasil untuk “{{query}}”',
}

export const PROFILE_COPY: CopyRecord<Profile> = {
  [Profile.title]: 'Profil Mitra',

  [Profile.email]: 'Tambahkan Alamat Email',
  [Profile.emailPlaceholder]: 'Masukkan alamat email mitra yang baru.',
  [Profile.labelPlaceholder]: 'Label (e.g. "Utama")',

  [Profile.contact]: 'Tambahkan Nomor Kontak',
  [Profile.contactDescription]: 'Masukkan nomor kontak mitra yang baru.',
  [Profile.typePlaceholder]: 'Pilih tipe...',
  [Profile.typePhone]: 'Nomor Telepon',
  [Profile.typeMobile]: 'Nomor HP',

  [Profile.editBasicInfo]: 'Ubah Basic Informasi',
  [Profile.partnerLogo]: 'Logo Mitra',
  [Profile.basicInfo]: 'Basic Informasi',
  [Profile.addressInfo]: 'Alamat',
  [Profile.provincePlaceholder]: 'Pilih provinsi',
  [Profile.cityPlaceholder]: 'Pilih kota',

  [Profile.noAddress]: 'Alamat tidak dimasukkan',
  [Profile.noEmail]: 'Email tidak dimasukkan',
  [Profile.noContact]: 'Nomor kontak tidak dimasukkan',

  [Profile.overview]: 'Overview',
  [Profile.contactInfo]: 'Informasi Kontak',
  [Profile.partnerProfile]: 'Profil Mitra',
  [Profile.labelSchool]: 'Sekolah/Kursus',
  [Profile.labelAddress]: 'Alamat',
  [Profile.deletePhoneTitle]:
    'Apakah Anda yakin ingin menghapus nomor kontak Anda?',
  [Profile.deletePhoneMessage]:
    'Anda akan menghapus {{phone}} ({{label}}) dari akun Anda.',
  [Profile.deleteEmailTitle]:
    'Apakah Anda yakin ingin menghapus alamat email Anda?',
  [Profile.deleteEmailMessage]:
    'Anda akan menghapus {{email}} ({{label}}) dari akun Anda.',
  [Profile.headingEmail]: 'Email',
  [Profile.headingPhone]: 'Nomor Telepon atau Ponsel',
}

export const ADMINISTRATOR_COPY: CopyRecord<Administrator> = {
  [Administrator.titleStep1]: 'Atur akses Admin',
  [Administrator.descStep1]: 'Tambahkan sampai 5 Admin kedalam Dashboard Anda.',
  [Administrator.titleStep2]: 'Pilih akses',
  [Administrator.descStep2]:
    'Pilih akses yang bisa dan tidak bisa diakses untuk setiap Admin.',
  [Administrator.titleStep3]: 'Pilih sekolah',
  [Administrator.descStep3]:
    'Spesifikasikan sekolah mana yang admin bisa akses.',

  [Administrator.titleBlankState]: 'Daftarkan Admin Sekarang!',
  [Administrator.descBlankState]:
    'Anda bisa menambahkan sampai dengan 5 Admin.',
  [Administrator.actionBlankState]: 'Tambahkan Admin Dashboard',

  [Administrator.titleModalAddAdmin]: 'Tambah admin',
  [Administrator.descModalAddAdmin]:
    'Anda akan menambahkan admin baru ke dashboard Anda, klik “Submit” untuk memproses.',

  [Administrator.addAdminBtn]: 'Tambah admin baru',
  [Administrator.alertMaxUser]:
    'Maaf, Anda telah mencapai jumlah maksimum pengguna mitra.',
  [Administrator.addUserSuccessTitle]: 'Sukses menambahkan admin baru!',
  [Administrator.addUserSuccessDesc]: `Kami telah mengirimkan email undangan kepada admin baru Anda termasuk instruksi untuk menyetel akun mereka.`,

  [Administrator.removeFormAdmin]: `Hapus formulir ini
  `,
}

export const LEADS_WELCOME_COPY: CopyRecord<LeadsWelcome> = {
  [LeadsWelcome.title]: 'Welcome to Leads Management!',
  [LeadsWelcome.firstOptionTitle]: 'Dapatkan Pelajar',
  [LeadsWelcome.firstOptionContent]: `Tawarkan ${APP_NAME} kepada pelajar Anda untuk meningkatkan pendaftaran di sekolah Anda.`,
  [LeadsWelcome.secondOptionTitle]: `Beritahu tentang ${APP_NAME}`,
  [LeadsWelcome.secondOptionContent]: `Beritahu pelajar Anda, bahwa mereka dapat mendaftar di ${APP_NAME} dengan mengirim SMS melalui fitur ini.`,
  [LeadsWelcome.thirdOptionTitle]: 'Bantu pelajar Anda',
  [LeadsWelcome.thirdOptionContent]: `Buat biaya sekolah lebih murah dengan menawarkan ${APP_NAME} ke pelajar Anda.`,
  [LeadsWelcome.registerTitle]: 'Daftarkan Pelajar Anda Sekarang!',
  [LeadsWelcome.registerContent]:
    'Tambah leads, pantau progress, dan lihat konversi pelajar Anda dengan Manajemen Lead.',
}

export const LEADS_CONTENT_COPY: CopyRecord<LeadsContent> = {
  [LeadsContent.title]: 'Rekomendasikan Pelajar',

  [LeadsContent.addLeadTitle]: 'Rekomendasikan Pelajar',
  [LeadsContent.addLeadSubTitle]:
    'Masukkan informasi pelajar yang ingin anda rekomendasikan ke Danacita',
  [LeadsContent.addLeadContent]: `Pelajar yang Anda rekomendasikan atau pelajar yang tertarik dengan pembiayaan ${APP_NAME} disebut dengan Leads`,

  [LeadsContent.leadTypeFilter]: 'Jenis Lead',
  [LeadsContent.schoolFilter]: 'Pilih Kampus/Lembaga',
  [LeadsContent.loanStatusFilter]: 'Status Aplikasi',
  [LeadsContent.levelFilter]: 'Progress',

  [LeadsContent.leadTypeFilterPlacholder]: 'Pilih Lead',
  [LeadsContent.schoolFilterPlaceholder]: 'Pilih Kampus/Lembaga',
  [LeadsContent.loanStatusFilterPlaceholder]: 'Pilih Status Aplikasi',
  [LeadsContent.levelFilterPlaceholder]: 'Pilih Progress',

  [LeadsContent.dateInfo]: 'sampai dengan {{date}}',
  [LeadsContent.leadInfoTitle]: 'Leads',
  [LeadsContent.totalLead]: 'Jumlah Leads',
  [LeadsContent.thisMonthLead]: '30 hari lalu',
  [LeadsContent.lastWeekLead]: '7 hari lalu',

  [LeadsContent.convertionRateTitle]: 'Konversi',
  [LeadsContent.convertionRateRegistration]: 'Pendaftar',
  [LeadsContent.convertionRateApplication]: 'Aplikasi',
  [LeadsContent.convertionRateActivation]: 'Aktivasi',

  [LeadsContent.tabAllLeads]: 'Semua Leads',
  [LeadsContent.tabDraft]: 'Drafts',
  [LeadsContent.tabErrors]: 'Errors',

  [LeadsContent.headingResendSMS]: 'Notify Lead',
  [LeadsContent.headingType]: 'Tipe',
  [LeadsContent.headingName]: 'Nama (Belakang, Depan)',
  [LeadsContent.headingBeneficiary]: 'Nama Pelajar (Anak)',
  [LeadsContent.headingEmail]: 'Email',
  [LeadsContent.headingUsername]: 'Nomor Handphone',
  [LeadsContent.headingSchool]: 'Sekolah',
  [LeadsContent.headingLoanProduct]: 'Loan Product',
  [LeadsContent.headingDateAdded]: 'Data ditambahkan',
  [LeadsContent.headingAddedByName]: 'Ditambahkan oleh',
  [LeadsContent.headingLeadStatus]: 'Status',
  [LeadsContent.headingLeadProgress]: 'Progress',
  [LeadsContent.headingAction]: 'Action',
  [LeadsContent.headingDatetimeCreated]: 'Diunggah pada',
  [LeadsContent.headingUploadedBy]: 'Diunggah oleh',
  [LeadsContent.headingErrorFile]: 'File Error',
  [LeadsContent.headingIdNumber]: 'ID Pelajar',

  [LeadsContent.resendSMSHoverInfo]: 'Send follow-up SMS & E-mail',
  [LeadsContent.unableToResendSMSHoverInfo]:
    'You can only send one follow-up SMS & E-mail per user',

  [LeadsContent.resendSMSSuccessTitle]: 'Follow-up SMS & E-mail sent!',
  [LeadsContent.resendSMSSuccessDesc]:
    'You have successfully sent a follow-up SMS & E-mail.',
  [LeadsContent.resendSMSErrorTitle]: 'Sending failed',
  [LeadsContent.resendSMSErrorDesc]:
    'Send follow-up SMS & E-mail failed, please try again.',

  [LeadsContent.filterBy]: 'Filter',

  [LeadsContent.firstModalTitle]: 'Selamat datang di Leads Manajemen',
  [LeadsContent.firstModalContent]: `Halo! 👋 Kami telah mengembangkan cara mudah bagi Anda untuk merekomendasikan ${APP_NAME} dan menaikkan tingkat pendaftaran dan retensi Anda.`,
  [LeadsContent.secondModalTitle]: 'Apa itu Lead?',
  [LeadsContent.secondModalContent]: `Leads adalah tempat untuk Anda merekomendasikan ${APP_NAME} kepada pelajar Anda. Mereka dapat menjadi pelajar baru Anda yang mencari opsi pembayaran alternatif atau yang sedang membutuhkan pembiayaan tambahan.`,
  [LeadsContent.thirdModalTitle]: 'Bagaimana cara menambahkan Lead?',
  [LeadsContent.thirdModalContent]:
    'Berikan dan masukan informasi dasar pada form yang sudah tersedia. Anda juga bisa menambahkan informasi detail lainnya secara opsional.',
  [LeadsContent.fourthModalTitle]: 'Bagaimana cara melihat prospek Lead?',
  [LeadsContent.fourthModalContent]:
    'Lacak dan lihat prospek lead Anda melalui tabel status dan progress.',
  [LeadsContent.fifthModalTitle]: 'Akses Draft Anda',
  [LeadsContent.fifthModalContent]:
    'Tulis prospek Anda dan simpan untuk nanti. Akses semua draft yang telah Anda simpan di tab tertentu.',
  [LeadsContent.sixthModalTitle]: 'Kirim SMS pemberitahuan',
  [LeadsContent.sixthModalContent]: `Kirim atau submit leads Anda untuk mengirimkan SMS kepada pelajar Anda. SMS tersebut menginformasikan kepada siswa bahwa mereka telah direkomendasikan untuk mendapatkan pembiayaan dengan ${APP_NAME}.`,

  [LeadsContent.leadsPopoverTitle]: 'Leads',
  [LeadsContent.leadsPopoverContent]:
    'Lacak berapa banyak prospek yang telah Anda kirimkan secara total dan bandingkan dengan berapa banyak yang telah Anda tambahkan selama sebulan atau minggu terakhir.',
  [LeadsContent.convertionRatePopoverTitle]: 'Konversi',
  [LeadsContent.convertionRatePopoverContent]:
    'Lacak tingkat konversi Anda dengan memeriksa berapa banyak yang telah mendaftar, berapa banyak yang telah mengajukan rencana pembiayaan, dan berapa banyak yang telah mengaktifkan kontrak.',
  [LeadsContent.progressPopoverTitle]: 'Progress',
  [LeadsContent.progressPopoverContent]: `Lacak prospek pelajar Anda dengan mudah, Anda bisa melihat progress pelajar Anda dalam proses pengajuan pembiayaan di ${APP_NAME}.`,
  [LeadsContent.draftsPopoverTitle]: 'Drafts',
  [LeadsContent.draftsPopoverContent]:
    'Simpan lead Anda untuk kembali dikirimkan notifikasi di lain waktu. Akses draft dengan hanya mengklik tab Drafts.',
  [LeadsContent.submitPopoverTitle]: 'Submit Draft',
  [LeadsContent.submitPopoverContent]:
    'Anda bisa mengirim lead kembali setelah menyimpan didalam draft. Notifikasi SMS akan dikirimkan apabila Anda mengirim lead.',
  [LeadsContent.createLeadsModalTitle]: 'Tambah Lead Baru',
  [LeadsContent.StudentInfoLabel]: 'Informasi Pelajar',
  [LeadsContent.firstNameFieldLabel]: 'Nama Depan',
  [LeadsContent.firstNameFieldPlaceholder]: 'Nama awal',
  [LeadsContent.lastNameFieldLabel]: 'Nama Belakang',
  [LeadsContent.lastNameFieldPlaceholder]: 'Nama akhir',
  [LeadsContent.emailFieldLabel]: 'Email',
  [LeadsContent.mobileNumberFieldLabel]: 'Nomor Hp',
  [LeadsContent.mobileNumberFieldPlaceholder]: '8xx xxxx xxxx',
  [LeadsContent.loanProductFieldLabel]: 'Pilih produk',
  [LeadsContent.loanProductFieldPlaceholder]: 'Product',
  [LeadsContent.dateOfBirthFieldLabel]: 'Tanggal Lahir',
  [LeadsContent.dateOfBirthFieldPlaceholder]: 'YYYY-MM-DD',
  [LeadsContent.dateOfBirthFieldValidationError]:
    'Tanggal harus dalam format yyyy-mm-dd',
  [LeadsContent.schoolFieldLabel]: 'Sekolah',
  [LeadsContent.additionalInfoLabel1]: 'Tambahan Informasi (Opsional)',
  [LeadsContent.additionalInfoLabel2]:
    '(Tingkatan, Program/Mata pelajaran, Nomor ID, Alamat pelajar)',
  [LeadsContent.degreeFieldLabel]: 'Tingkat',
  [LeadsContent.degreeFieldPlaceholder]: 'S1, S2',
  [LeadsContent.courseFieldLabel]: 'Program/mata pelajaran',
  [LeadsContent.courseFieldPlaceholder]: 'Program/mata pelajaran',
  [LeadsContent.IDNumberFieldLabel]: 'Nomor induk pelajar',
  [LeadsContent.IDNumberFieldPlaceholder]: 'Nomor induk',
  [LeadsContent.addressFieldLabel]: 'Alamat pelajar',
  [LeadsContent.addressFieldPlaceholder]: 'Alamat pelajar',
  [LeadsContent.cityFieldLabel]: 'Kota pelajar',
  [LeadsContent.cityFieldPlaceholder]: 'Kota pelajar',
  [LeadsContent.provinceFieldLabel]: 'Provinsi pelajar',
  [LeadsContent.provinceFieldPlaceholder]: 'Provinsi pelajar',

  [LeadsContent.uploadBatchLeadsModalTitle]: 'Unggah Leads Batch',
  [LeadsContent.uploadBatchLeadsModalHint]:
    'Pastikan file anda dalam format CSV/XSLX',
  [LeadsContent.uploadBatchLeadsModalUploadFileSection]: 'Unggah File',
  [LeadsContent.uploadBatchLeadsModalUploadFileSectionPlacholder]:
    'Letakkan file disini',
  [LeadsContent.uploadBatchLeadsTitleDescription]: 'Batch Upload',
  [LeadsContent.uploadBatchLeadsDescription]:
    'Mohon unggah file leads anda dengan ketentuan kolom sebagai berikut: school, first_name, last_name, mobile_phone, email, course, loan_product, date_of_birth, create_user (isi dengan y atau n).',

  [LeadsContent.downloadCSVSampleButton]: `<0>Download template</0>`,

  [LeadsContent.previewBatchLeadsModalTitle]: 'Rangkuman',
  [LeadsContent.previewBatchLeadsModalUploadSuccess]: 'File berhasil diunggah',

  [LeadsContent.uploadStatusModalSuccessTitle]: 'Sukses',
  [LeadsContent.uploadStatusModalSuccessDescription]:
    'File anda telah berhasil diunggah',
  [LeadsContent.uploadStatusModalErrorTitle]: 'Unggah File Gagal',
  [LeadsContent.uploadStatusModalErrorDescription]: `Unduh dan periksa kembali file yang anda unggah. Pastikan seluruh format sudah terisi dan sesuai dengan ketentuan.`,

  [LeadsContent.uploadProcessModalTitle]: `File sedang di proses`,
  [LeadsContent.uploadProcessModalDescription]: `Mohon periksa kembali file anda setelah proses pengolahan data leads suadah selesai. `,

  [LeadsContent.sendSMSModalTitle]: 'Mengirim SMS',
  [LeadsContent.sendSMSModalContent]: `Anda akan mengirimkan SMS kepada pelajar ini untuk memberitahu bahwa pelajar bisa mendaftar  ke ${APP_NAME}. Apakah Anda ingin memproses SMS?`,
  [LeadsContent.thankYouModalTitle]: 'Terimakasih',
  [LeadsContent.thankYouModalContent]:
    'Kami telah mengirimkan SMS kepada pelajar Anda. Silahkan lihat progress lead anda melalui dashboard.',

  [LeadsContent.aggregateTitle]: 'Konversi Leads',
  [LeadsContent.aggregateTotal]: 'Total Leads',
  [LeadsContent.aggregateRegistration]: 'Total Registrasi Akun',
  [LeadsContent.aggregateApplication]: 'Total Pengajuan',
  [LeadsContent.aggregateActivated]: 'Pembiayaan Aktif',
  [LeadsContent.aggregateAmount]: 'Jumlah Pencairan Dana dari Leads',

  [LeadsContent.tooltipProgress]:
    'Cek dan pantau progress pelajar yang Anda rekomendasikan ke Danacita dengan progress bar ini.',
  [LeadsContent.tooltipProgressNotYetRegister]: 'Belum daftar',
  [LeadsContent.tooltipProgressRegistered]: 'Sudah daftar',
  [LeadsContent.tooltipProgressSubmitted]: 'Sudah mengajukan',
  [LeadsContent.tooltipProgressActivated]: 'Cicilan sudah aktif',
}

export const RISK_COPY: CopyRecord<Risk> = {
  [Risk.title]: 'Blocking Access',

  [Risk.selectCohort]: 'Pilih Kelompok',
  [Risk.selectCohortPlaceholder]: `{{month}}`,

  [Risk.summary]: 'Ringkasan',
  [Risk.dateActivated]: 'Tanggal Aktif',
  [Risk.dateActivatedPopover]:
    'Mewakili tanggal pencairan pertama yang dilakukan ke sekolah.',
  [Risk.nextRepaymentDeadline]: 'Deadline pembayaran berikutnya',
  [Risk.nextRepaymentDeadlinePopover]:
    'Mengilustrasikan tanggal jatuh tempo berikutnya untuk siswa dalam kelompok.',
  [Risk.finalDisbursement]: 'Pencairan akhir berdasarkan kinerja saat ini',
  [Risk.finalDisbursementPopover]:
    'Memproyeksikan jumlah pencairan akhir Anda berdasarkan kinerja saat ini.',
  [Risk.summaryCoachmarkingContent]:
    'Keep track of pertinent details and never skip a beat when it comes to monitoring your cohort.',

  [Risk.performance]: 'Performa',
  [Risk.activations]: 'Aktivasi',
  [Risk.asOf]: 'sejak',
  [Risk.npl]: 'Net Principal NPL',
  [Risk.netPrincipalNPL]: 'Net Principal NPL ',
  [Risk.approvedPrincipal]: 'Total Approved Principal for Cohort ',
  [Risk.nplStudentCount]: 'NPL student count ',
  [Risk.nonNplStudentCount]: 'Non NPL student count ',
  [Risk.performanceCoachmarkingContent]:
    'Monitor how your cohort is fairing month on month. We stop computing the NPL ratio a day after the cohort’s repayment deadline and update calculations daily for the current month.',

  [Risk.loans]: 'Loans',
  [Risk.headingRepaymentStatus]: 'Status Pembayaran',
  [Risk.headingName]: 'Nama (Akhir, Awal)',
  [Risk.headingIdNum]: 'Nomor ID',
  [Risk.headingCourse]: 'Program',
  [Risk.headingReference]: 'Referensi #',
  [Risk.headingLoanProduct]: 'Skema Cicilan',
  [Risk.headingDateActivated]: 'Tanggal Aktivasi',
  [Risk.loansCoachmarkingContent]:
    'Easily navigate through the list of students in your cohort and access their repayment status, name, ID number and more.',
  [Risk.headingInstitution]: 'Institusi',
  [Risk.headingReportingDate]: 'Tanggal Laporan',
  [Risk.headingTotalStudent]: 'Jumlah Pelajar',
  [Risk.headingDownloadReport]: 'Unduh Laporan',

  [Risk.MonthlyReport]: 'Laporan Bulanan',
  [Risk.StudentList]: 'Daftar Pelajar',
}

export const PARTNER_PROFILE_COPY: CopyRecord<PartnerProfile> = {
  [PartnerProfile.title]: 'Pengaturan Profil',

  [PartnerProfile.joinedDate]: `Tanggal Bergabung: {{date}}`,
  [PartnerProfile.population]: 'Jumlah Pelajar',
  [PartnerProfile.activations]: 'Total Aktivasi',
  [PartnerProfile.program]: 'Program',
  [PartnerProfile.completion]: `{{percentage}} Kelengkapan Profil`,

  [PartnerProfile.basicInformationSection]: 'Informasi Lembaga',
  [PartnerProfile.programSection]: 'Program Studi',
  [PartnerProfile.paymentsSection]: 'Pembayaran',
  [PartnerProfile.imagesSection]: 'Foto',

  [PartnerProfile.addressSectionTitle]: 'School Address',
  [PartnerProfile.addressSectionContent]:
    'Please make sure all information are updated.',

  [PartnerProfile.socialMediaAccountsSectionTitle]: 'Social Media Accounts',
  [PartnerProfile.socialMediaAccountsSectionContent]:
    'Please make sure all information are updated.',

  [PartnerProfile.contactNumberSectionTitle]: 'Contact Numbers',
  [PartnerProfile.contactNumberSectionContent]:
    'Please make sure all information are updated.',

  [PartnerProfile.contactNumberRemoveModalTitle]: 'Remove Contact Number',
  [PartnerProfile.contactNumberRemoveModalDescription]:
    'You are about to remove a contact number. Click "Remove" to proceed and "Cancel" to exit',

  [PartnerProfile.populationSectionTitle]: 'Jumlah Pelajar',
  [PartnerProfile.populationSectionContent]:
    'Mohon selalu perbarui data pelajar yang mendapat pendanaan dari stiap tim.',
  [PartnerProfile.populationSectionDate]: 'Jumlah Pelajar per {{date}}',

  [PartnerProfile.contactPersonSectionTitle]: 'Daftar Kontak',
  [PartnerProfile.contactPersonSectionContent]:
    'Mohon selalu perbarui nomor kontak untuk staff tim anda yang menjadi penanggung jawab.',

  [PartnerProfile.programSectionTitle]: 'Program Studi',
  [PartnerProfile.programsSectionText]:
    'Mohon masukan informasi program studi untuk jenjang Pasca Sarjana/Sarjana/Kursus. Informasi ini akan digunakan untuk melengkapi proses pengajuan dana pendidikan untuk para pelajar. Informasi ini juga akan digunakan sebagai informasi tambahan di halaman mitra pada situs kami.',
  [PartnerProfile.undergraduateProgramsSectionTitle]: 'Program Sarjana',
  [PartnerProfile.graduateProgramsSectionTitle]: 'Program Pasca Sarjana',
  [PartnerProfile.shortCoursesSectionTitle]: 'Program Kursus',

  [PartnerProfile.registrationFeesSectionTitle]: 'Biaya UKT dan Biaya Lainnya',
  [PartnerProfile.registrationFeesSectionContent]:
    'Mohon Unggah salinan terbaru dari tarif kuliah Anda & biaya lainnya. Biaya ini akan diposting di halaman khusus sekolah Anda di situs web kami.',

  [PartnerProfile.paymentPoliciesSectionTitle]: 'Kebijakan Pembayaran',
  [PartnerProfile.paymentPoliciesSectionContent]:
    'Mohon Jawab survei singkat kami tentang kebijakan pembayaran. Ini akan memungkinkan tim operasi kami untuk memproses aplikasi lebih cepat.',

  [PartnerProfile.internalFinancingProgramSectionTitle]:
    'Program Pendanaan Internal',
  [PartnerProfile.internalFinancingProgramSectionContent]:
    'Mohon Isi program pembiayaan internal Anda kepada kami. Ini akan menginformasikan bagaimana kami dapat meningkatkan untuk membantu melayani siswa Anda dengan lebih baik.',

  [PartnerProfile.paymentDeadlinesSectionTitle]: 'Jadwal Pembayaran',
  [PartnerProfile.paymentDeadlinesSectionContent]:
    'Mohon berikan informasi terkait jadwal pembayaran UKT dan minimal pembayaran DP UKT.',

  [PartnerProfile.imagesSectionTitle]: 'Images',
  [PartnerProfile.imagesSectionContent]:
    'Perbarui selalu foto anda dengan mengunggah foto terbaru.',
  [PartnerProfile.schoolPhoto]: 'Foto Lembaga',
  [PartnerProfile.schoolLogo]: 'Logo Lembaga',
  [PartnerProfile.studentID]: 'Kartu Mahasiswa (KTM)',
  [PartnerProfile.schoolBilling]: 'Bukti Tagihan UKT',
  [PartnerProfile.stockPhotos]: 'Stok Foto',
  [PartnerProfile.imageSample]: 'Contoh Foto',
  [PartnerProfile.pictureSchool]: 'Tambahkan Foto Lembaga',
  [PartnerProfile.pictureLogo]: 'Tambahkan foto logo lembaga',
  [PartnerProfile.pictureStudentID]: 'Tambahkan foto kartu mahasiswa',
  [PartnerProfile.pictureCertificate]: 'Tambahkan foto bukti tagihan UKT',
  [PartnerProfile.pictureClass]: 'Tambahkan foto kegiatan lembaga anda',
  [PartnerProfile.fileType]: 'JPEG atau PNG',

  [PartnerProfile.populationModalTitle]: 'Jumlah Pelajar',
  [PartnerProfile.populationModalLabelInput1]: 'Total Pelajar Sekarang',
  [PartnerProfile.populationModalPlaceholderInput1]: 'E.g. 10000',
  [PartnerProfile.populationModalButtonText]: 'Simpan',

  [PartnerProfile.contactPersonRemoveModalTitle]: 'Hapus Kontak',
  [PartnerProfile.contactPersonRemoveModalDescription]:
    'Apakah anda yakin akan menghapus kontak? Tekan “Hapus” untuk konfirmasi atau tekan “Batal” untuk pembatalan.',

  [PartnerProfile.shortCoursesModalTitle]: 'Program Kursus',
  [PartnerProfile.graduateProgramsModalTitle]: 'Program Sarjana',
  [PartnerProfile.undergraduateProgramsModalTitle]: 'Program Pasca Sarjana',
  [PartnerProfile.undergraduateProgramsModalLabelInput1]: 'Nama Program Studi',
  [PartnerProfile.undergraduateProgramsModalPlaceholderInput1]:
    'E.g. Teknik Informatika',
  [PartnerProfile.undergraduateProgramsModalLabelInput2]: 'Gelar',
  [PartnerProfile.undergraduateProgramsModalPlaceholderInput2]: 'E.g. S1',
  [PartnerProfile.undergraduateProgramsModalLabelInput3]: 'Upload batch',
  [PartnerProfile.undergraduateProgramsModalPlaceholderInput3]:
    'Upload your file here',
  [PartnerProfile.undergraduateProgramsModalButtonText]: 'Simpan Program Studi',

  [PartnerProfile.schoolAddressModalTitle]: 'School Address',
  [PartnerProfile.schoolAddressModalInputProvinceLabel]: 'Current Province',
  [PartnerProfile.schoolAddressModalInputProvincePlaceholder]:
    'Find your current province',
  [PartnerProfile.schoolAddressModalInputCityLabel]: 'Current City',
  [PartnerProfile.schoolAddressModalInputCityPlaceholder]:
    'Find your current city',
  [PartnerProfile.schoolAddressModalInputDistrictLabel]: 'District',
  [PartnerProfile.schoolAddressModalInputDistrictPlaceholder]:
    'Input your district',
  [PartnerProfile.schoolAddressModalInputAddressLine1Label]: 'Address Line 1',
  [PartnerProfile.schoolAddressModalInputAddressLine1Placeholder]:
    'Input address line 1 (e.g. 30E Blue Tower, Asul St.)',
  [PartnerProfile.schoolAddressModalInputAddressLine1Notes]:
    'House no. / Unit no. / Building name, Street name',
  [PartnerProfile.schoolAddressModalInputAddressLine2Label]: 'Address Line 2',
  [PartnerProfile.schoolAddressModalInputAddressLine2Placeholder]:
    'Input address line 2 (e.g. Blue Meadows Subd.)',
  [PartnerProfile.schoolAddressModalInputAddressLine2Notes]:
    'Sitio / Area / Cluster / Subdivision',

  [PartnerProfile.addFacebookURL]: 'Facebook URL or ID',
  [PartnerProfile.addSchoolFacebookURL]: 'Add School’s Facebook URL',

  [PartnerProfile.facebookAccountModalTitle]: '{{prefix}} Facebook Account',
  [PartnerProfile.facebookAccountModalInputLabel]: 'Facebook URL or ID',
  [PartnerProfile.facebookAccountModalInputPrefix]: 'facebook.com/',
  [PartnerProfile.facebookAccountModalInputPlaceholder]: 'Facebook ID',
  [PartnerProfile.facebookAccountModalStep1]:
    '1. Open your Facebook app and login to your Facebook account.',
  [PartnerProfile.facebookAccountModalStep2]:
    '2. From the homepage, click the menu icon on the upper right (looks like three horizontal lines).',
  [PartnerProfile.facebookAccountModalStep3]:
    '3. Click your profile photo or "See your profile" under your name.',
  [PartnerProfile.facebookAccountModalStep4]:
    '4. Click the "Profile Settings" tab (looks like three dots).',
  [PartnerProfile.facebookAccountModalStep5]:
    '5. Scroll down to "Your Profile Link" and click "Copy Link" to copy your complete Facebook profile (should look like this: www.facebook.com/your_fb_id).',

  [PartnerProfile.contactNumberModalTitle]: 'Add Contact Number',
  [PartnerProfile.contactNumberModalInputLabel]: 'Contact number',
  [PartnerProfile.contactNumberModalDropdownOption1]: 'Primary',
  [PartnerProfile.contactNumberModalDropdownOption2]: 'Secondary',

  [PartnerProfile.contactPersonModalTitle]: 'Info Kontak',
  [PartnerProfile.contactPersonModalLabelInput1]: 'Nama',
  [PartnerProfile.contactPersonModalLabelInput2]: 'Divisi/Departemen',
  [PartnerProfile.contactPersonModalLabelInput3]: 'Email',
  [PartnerProfile.contactPersonModalLabelInput4]: 'Nomor HP',
  [PartnerProfile.contactPersonModalPlaceholderInput1]: 'Nama Lengkap',
  [PartnerProfile.contactPersonModalPlaceholderInput2]: 'Keuangan',
  [PartnerProfile.contactPersonModalPlaceholderInput3]: 'email@gmail.com',
  [PartnerProfile.contactPersonModalPlaceholderInput4]: 'Mobile',
  [PartnerProfile.contactPersonModalPlaceholderInput5]: '9123123123',
  [PartnerProfile.contactPersonModalButtonText]: 'Simpan Info Kontak',

  [PartnerProfile.paymentDeadlineModalTitle]: 'Jadwal Pembayaran',
  [PartnerProfile.paymentDeadlineModalTypeLabel]: 'Jenis Jadwal Pembayaran',
  [PartnerProfile.paymentDeadlineModalTypePlaceholder]:
    'E.g. Pembayaran Tengah Semester',
  [PartnerProfile.paymentDeadlineModalMinRequiredPaymentLabel]:
    'Minimal DP UKT',
  [PartnerProfile.paymentDeadlineModalMinRequiredPaymentPlaceholder]:
    'E.g. DP 30%',
  [PartnerProfile.paymentDeadlineModalDateRangeLabel]:
    'Pilih Tanggal Pembayaran',

  [PartnerProfile.paymentPoliciesModalTitle]: 'Kebijakan Pembayaran',
  [PartnerProfile.paymentPoliciesModalButtonText]: 'Simpan',

  [PartnerProfile.imageSampleModalTitle]: 'Contoh Foto',

  [PartnerProfile.tipsForSchoolPhotoTitle]: 'Syarat untuk foto sekolah',
  [PartnerProfile.tipsForSchoolPhotoContent1]:
    'Menunjukkan bangunan sekolah, lembaga anda.',
  [PartnerProfile.tipsForSchoolPhotoContent2]:
    'Memiliki resolusi foto yang tinggi.',
  [PartnerProfile.tipsForSchoolPhotoContent3]:
    'Telah disetujui untuk di unggah di situs kami.',

  [PartnerProfile.tipsForSchoolLogoTitle]: 'Syarat untuk foto Logo Lembaga',
  [PartnerProfile.tipsForSchoolLogoContent1]:
    'Merupakan lopo lembaga anda yang terbaru.',
  [PartnerProfile.tipsForSchoolLogoContent2]:
    'Memiliki resolusi foto yang tinggi.',
  [PartnerProfile.tipsForSchoolLogoContent3]:
    'Telah disetujui untuk di unggah di situs kami.',

  [PartnerProfile.tipsForStockPhotosTitle]: 'Syarat untuk stok foto',
  [PartnerProfile.tipsForStockPhotosContent1]:
    'Foto yang diunggah telah disetujui oleh para mahasiswa dan dengan sepengetahuan semua pihak terkait sebagai bahan publikasi.',
  [PartnerProfile.tipsForStockPhotosContent2]:
    'Foto harus memiliki resolusi yang tinggi.',
  [PartnerProfile.tipsForStockPhotosContent3]:
    'Telah disetujui untuk di unggah di situs kami.',

  [PartnerProfile.tipsForStudentIdTitle]:
    'Syarat untuk foto Kartu Mahasiswa (KTM)',
  [PartnerProfile.tipsForStudentIdContent1]:
    'Merupakan contoh kartu KTM yang terbaru.',
  [PartnerProfile.tipsForStudentIdContent2]:
    'Memiliki resolusi foto yang tinggi.',
  [PartnerProfile.tipsForStudentIdContent3]:
    'Telah disetujui untuk di unggah di situs kami.',

  [PartnerProfile.tipsForSchoolBillingTitle]: 'Syarat untuk foto bukti UKT',
  [PartnerProfile.tipsForSchoolBilling1]:
    'Merupakan contoh bukti pembayaran UKT yang terbaru.',
  [PartnerProfile.tipsForSchoolBilling2]: 'Memiliki resolusi foto yang tinggi.',
  [PartnerProfile.tipsForSchoolBilling3]:
    'Telah disetujui untuk di unggah di situs kami.',

  [PartnerProfile.internalFinancingProgramModalTitle]:
    '{{type}} Program Pendanaan Internal',
  [PartnerProfile.internalFinancingProgramModalNameFieldLabel]: 'Nama',
  [PartnerProfile.internalFinancingProgramModalNameFieldPlaceholder]:
    'E.g. Program Pendanaan',
  [PartnerProfile.internalFinancingProgramModalTenorFieldLabel]: 'Tenor',
  [PartnerProfile.internalFinancingProgramModalTenorFieldPlaceholder]:
    'E.g. 3 bulan',
  [PartnerProfile.internalFinancingProgramModalInterestRateFieldLabel]:
    'Suku Bunga',
  [PartnerProfile.internalFinancingProgramModalInterestRateFieldPlaceholder]:
    'E.g. 2%',
  [PartnerProfile.internalFinancingProgramModalOtherFeesFieldLabel]:
    'Biaya Lainnya (optional)',
  [PartnerProfile.internalFinancingProgramModalOtherFeesFieldPlaceholder]:
    'E.g. 5% denda telat bayar',

  [PartnerProfile.removePaymentDeadlineConfirmationModalTitle]:
    'Hapus Jadwal Pembayaran',
  [PartnerProfile.removePaymentDeadlineConfirmationModalContent]:
    'Apakah anda yakin akan menghapus Jadwal Pembayaran? Tekan “Hapus” untuk konfirmasi atau tekan “Batal” untuk pembatalan.',

  [PartnerProfile.removeInternalFinancingProgramConfirmationModalTitle]:
    'Hapus Program Pendanaan Internal',
  [PartnerProfile.removeInternalFinancingProgramConfirmationModalContent]:
    'Apakah anda yakin akan menghapus Program Pendanaan Internal? Tekan “Hapus” untuk konfirmasi atau tekan “Batal” untuk pembatalan.',
  [PartnerProfile.removeProgramConfirmationModalTitle]: 'Hapus Program',
  [PartnerProfile.removeProgramConfirmationModalContent]:
    'Apakah anda yakin akan menghapus program? Tekan “Hapus” untuk konfirmasi atau tekan “Batal” untuk pembatalan.',
}

export const METRICS_COPY: CopyRecord<Metrics> = {
  [Metrics.title]: 'Metrik',

  [Metrics.itemMetricsTitle]: 'Metrik',
  [Metrics.itemBorrowersTitle]: 'Total Pengguna Danacita',
  [Metrics.itemCohortsTitle]: 'Kelompok',
  [Metrics.applicationsTabTitle]: 'Aplikasi',
  [Metrics.activationsTabTitle]: 'Aktivasi',
  [Metrics.activationsBarLabel]: '{{value}}\n({{count}} aktivasi)',
  [Metrics.activationsBarTooltipValue]: 'Total Nilai : {{value}}',
  [Metrics.activationsBarTooltipCount]: 'Total Jumlah : {{count}} aktivasi',
  [Metrics.degreeTabTitle]: 'Strata',
  [Metrics.yearLevelTabTitle]: 'Tahun Angkatan',
  [Metrics.borrowersTabTitle]: 'Peminjam',

  [Metrics.schoolFilterTitle]: 'Pilih Kampus',
  [Metrics.monthFilterTitle]: 'Pilih Bulan',
  [Metrics.schoolFilterPlaceholder]: 'Pilih Kampus',
  [Metrics.mothFilterPlaceholderStart]: 'Awal',
  [Metrics.mothFilterPlaceholderEnd]: 'Akhir',

  [Metrics.applicationsEmptyTitle]:
    'Belum ada data aplikasi yang dapat ditampilkan.',
  [Metrics.activationsEmptyTitle]:
    'Belum ada data aktivasi yang dapat ditampilkan.',
  [Metrics.borrowersEmptyTitle]: 'Belum ada pengajuan dari mahasiswa',
  [Metrics.cohortsDegreeEmptyTitle]: 'Belum ada data strata',
  [Metrics.cohortsYearEmptyTitle]: 'Belum ada data tahun angkatan',

  [Metrics.applicationsEmptyDescription]:
    'Seluruh data aplikasi pendanaan pendidikan akan ditampilkan di halaman ini.',
  [Metrics.activationsEmptyDescription]:
    'Seluruh data aktivasi pendanaan pendidikan akan ditampilkan di halaman ini.',
  [Metrics.borrowersEmptyDescription]:
    'Seluruh mahasiswa yang mengajukan dana pendidikan akan ditampilkan di halaman ini.',
  [Metrics.cohortsDegreeEmptyDescription]:
    'Seluruh data strata akan ditampilkan di halaman ini',
  [Metrics.cohortsYearEmptyDescription]:
    'Seluruh data tahun angkatan akan ditampilkan di halaman ini',
}

export const INTEGRATIONS_COPY: CopyRecord<Integrations> = {
  [Integrations.title]: 'API page',

  [Integrations.documentationTitleText]: `${APP_NAME}Pay APIs`,
  [Integrations.documentationInfoText]:
    'Lakukan proses pengajuan biaya dan pembayaran dana pendidikan yang lebih cepat dengan API dan UI dari kami.',
  [Integrations.keyText]: 'Keys',
  [Integrations.testKeyText]: 'Test',
  [Integrations.liveKeyText]: 'Live',

  [Integrations.keySecurityTextTitle]: 'Panduan Keamanan ⚠️',
  [Integrations.keySecurityTextInfo]:
    'Pergunakan API keys anda selayaknya kata sandi yang anda miliki. Semua orang yang memiliki REST API keys anda dapat mengirim kan notifikasi ke plarform anda. Mohon jangan bagikan kunci API ke dalam application code anda. Juga jangan sebarkan API key ini di GitHub atau tempat lain.',

  [Integrations.redirectText]: 'Redirect',
  [Integrations.placeholderRedirectURLText]: 'https://sample.url/endpoint',

  [Integrations.redirectFunctionalityTextTitle]: 'Fungsi Redirect',
  [Integrations.redirectFunctionalityTextInfo]:
    'Sediakan URL yang akan berfungsi sebagai redirect bagi siswa setelah mereka mengajukan aplikasi pinjaman mereka.',
  [Integrations.functionalityTextNote]:
    'Ini akan bermanfaat jika Anda ingin mengarahkan siswa Anda kembali ke portal sekolah Anda.',

  [Integrations.webhookText]: 'Webhook',
  [Integrations.placeholderWebhookURLText]: 'https://sample.url/endpoint',
  [Integrations.contractSignedWebhookTextTitle]: 'Penandatanganan Kontrak',
  [Integrations.contractSignedWebhookTextInfo]:
    'Pemberitahuan akan dikirim ke webhook ini saat siswa menandatangani kontrak mereka.',
  [Integrations.disbursementWebhookTextTitle]: 'Pencairan Dana',
  [Integrations.disbursementWebhookTextInfo]:
    'Pemberitahuan akan dikirim ke webhook ini saat pinjaman siswa dicairkan ke sekolah.',
  [Integrations.cancellationWebhookTextTitle]: 'Pembatalan',
  [Integrations.cancellationWebhookTextInfo]:
    'Notifikasi akan dikirimkan melalui webhook ketika cicilan dibatalkan.',
  [Integrations.allStatusWebhookTextTitle]: 'Semua Status',
  [Integrations.allStatusWebhookTextInfo]:
    'Notifikasi terbaru akan dikirimkan ke webhook ini saat ada perubahan status pengajuan biaya pendidikan yang tehubung dengan institusi Anda.',

  [Integrations.downloadKeyModalTitle]:
    'Anda akan mendownload {{name}} API key baru!',
  [Integrations.downloadKeyModalContent]:
    'Dengan mendownload API key yang baru maka izin akses dari API key sebelumnya akan dicabut. Pastikan untuk melakukan pengaturan ulang di setting API key anda.',

  [Integrations.keyHighlightText]:
    'Tekan download untuk membuat <0>{{apiKey}}</0>',
  [Integrations.apiKey]: 'API Key',
}

export const ADMISSIONS_COPY: CopyRecord<Admissions> = {
  [Admissions.title]: 'Admissions',
  [Admissions.subheading]: 'Selamat datang di Dasbor Danacita',

  [Admissions.filter]: 'Filter',
  [Admissions.allDays]: 'Setiap Hari',
  [Admissions.day]: 'Hari',
  [Admissions.days]: 'Hari',
  [Admissions.applyFilter]: 'Terapkan Filter',
  [Admissions.filterBy]: 'Filter',
  [Admissions.reset]: 'Reset',
  [Admissions.filterDateApplied]: 'Tanggal Pendaftaran',
  [Admissions.filterEnrollmentType]: 'Tipe Pendaftaran',
  [Admissions.filterProgramStudy]: 'Program',
  [Admissions.filterPartnerName]: 'Sekolah',

  [Admissions.headingDateApplied]: 'Tanggal Pendaftaran',
  [Admissions.headingEnrollmentType]: 'Tipe Pendaftaran',
  [Admissions.headingName]: 'Nama (Belakang, Depan)',
  [Admissions.headingMobilePhone]: 'Nomor Handphone',
  [Admissions.headingEmail]: 'Email',
  [Admissions.headingSchool]: 'Sekolah',
  [Admissions.headingProgramStudy]: 'Program',
  [Admissions.headingAction]: 'Informasi Aplikasi',

  [Admissions.overviewHeader]: 'Calon Siswa',
  [Admissions.overviewEmail]: 'Email',
  [Admissions.overviewMobileNumber]: 'Nomor Handphone',
  [Admissions.overviewDateOfBirth]: 'Tanggal Lahir',
  [Admissions.overviewEnrollmentType]: 'Tipe Pendaftar',
  [Admissions.overviewSchool]: 'Sekolah',
  [Admissions.overviewPreviousSchool]: 'Sekolah Sebelumnya',
  [Admissions.overviewDatetimeApplied]: 'Waktu Aplikasi',
  [Admissions.overviewShsStrand]: 'Jurusan',
  [Admissions.overviewProgramStudy]: 'Gelar',
}

export const PERMISSIONS_COPY: CopyRecord<Permissions> = {
  [Permissions.title]: 'Administrator',

  [Permissions.titleStep1]: 'Atur akses Admin',
  [Permissions.descStep1]: 'Tambahkan sampai 5 admin kedalam Dasbor Anda.',

  [Permissions.titleStep2]: 'Pilih akses',
  [Permissions.descStep2]:
    'Pilih akses yang bisa dan tidak bisa diakses untuk setiap admin.',

  [Permissions.titleStep3]: 'Pilih sekolah',
  [Permissions.descStep3]: 'Spesifikasikan sekolah mana yang admin bisa akses.',

  [Permissions.titleBlankState]: 'Daftarkan Admin Sekarang!',
  [Permissions.descBlankState]: `Anda dapat mendaftarkan ${REACT_APP_MAXIMUM_PARTNER_USER} akun admin untuk setiap kampus.`,

  [Permissions.tabAdministrators]: 'Administrator',
  [Permissions.tabRoles]: 'Jabatan',

  [Permissions.administratorsHeading]: 'Daftar Admin',
  [Permissions.rolesHeading]: 'Jabatan Admin',

  [Permissions.accessText]: 'Akses :',
  [Permissions.viewIcon]: 'Lihat',
  [Permissions.downloadIcon]: 'Unduh',
  [Permissions.editIcon]: 'Ubah',
  [Permissions.deleteIcon]: 'Hapus',

  [Permissions.headingName]: 'Nama',
  [Permissions.headingEmail]: 'Email',
  [Permissions.headingSchools]: 'Kampus',
  [Permissions.headingRole]: 'Jabatan',
  [Permissions.headingAction]: 'Tindakan',
  [Permissions.headingStudentData]: 'Data Siswa',
  [Permissions.allCampusStudentData]: 'Semua Kampus',
  [Permissions.selectedOnlyCampusStudentData]: 'Kampus Terpilih Saja',

  [Permissions.addUserSuccessTitle]: 'Sukses menambahkan admin baru!',
  [Permissions.addUserSuccessDesc]:
    'Kami telah mengirimkan email undangan kepada admin baru Anda termasuk instruksi untuk menyetel akun mereka.',

  [Permissions.editUserSuccessTitle]: 'Updated admin successfully!',
  [Permissions.editUserSuccessDesc]: `We have sent an email notification to the updated admin`,

  [Permissions.addNewAdminModalHeading]: 'Tambah Admin',
  [Permissions.addNewAdminModalFirstNameFieldLabel]: 'Nama Depan',
  [Permissions.addNewAdminModalLastNameFieldLabel]: 'Nama Belakang',
  [Permissions.addNewAdminModalEmailFieldLabel]: 'Email',
  [Permissions.addNewAdminModalRoleFieldLabel]: 'Jabatan',
  [Permissions.addNewAdminModalCampusFieldLabel]: 'Kampus',

  [Permissions.editAdminModalHeading]: 'Ubah Admin',
  [Permissions.editAdminModalFirstNameFieldLabel]: 'Nama Depan',
  [Permissions.editAdminModalLastNameFieldLabel]: 'Nama Belakang',
  [Permissions.editAdminModalEmailFieldLabel]: 'Email',
  [Permissions.editAdminModalRoleFieldLabel]: 'Jabatan',
  [Permissions.editAdminModalCampusFieldLabel]: 'Kampus',

  [Permissions.deleteAdminModalHeading]: 'Hapus Admin',
  [Permissions.deleteAdminModalContent]:
    'Apakah anda yakin akan menghapus akun ini?',
}

export const DIGEST_COPY: CopyRecord<Digest> = {
  [Digest.title]: 'Digest Validator Page',
  [Digest.documentationTitleText]: 'Digest Validator',
  [Digest.documentationInfoText]:
    'Masukkan data Anda di kolom Digest Validator untuk mendapatkan string dan digest yang Anda perlukan agar dapat mengakses sistem kami. ',
  [Digest.integrationsType]: 'Tipe Integrasi',
  [Digest.action]: 'Generate Digest',
  [Digest.copyClipboad]: 'Salin ke clipboard',
  [Digest.requiredField]: 'Required Fields for Digest',
  [Digest.addressLine1]: 'Address Line 1',
  [Digest.addressLine2]: 'Address Line 2',
  [Digest.balanceFromOlderTerms]: 'Balance from Older Terms',
  [Digest.city]: 'Kota',
  [Digest.currentDueTotal]: 'Total Biaya Semester Saat Ini',
  [Digest.dateOfBirth]: 'Tanggal Lahir',
  [Digest.degree]: 'Jenjang',
  [Digest.email]: 'Alamat Email Pelajar',
  [Digest.firstName]: 'Nama Depan Pelajar',
  [Digest.guardianFirstName]: 'Nama Depan Wali',
  [Digest.guardianLastName]: 'Nama Belakang Wali',
  [Digest.guardianMobileNumber]: 'Nomor Ponsel Wali',
  [Digest.lastName]: 'Nama Belakang Pelajar',
  [Digest.maritalStatus]: 'Status Pernikahan',
  [Digest.maxDaysInArrears]: 'Lama Pembayaran Tertunggak',
  [Digest.mobileNumber]: 'Nomor Ponsel Pelajar',
  [Digest.outstandingBalanceFromPreviousTerm]: 'Tunggakan Semester Sebelumnya',
  [Digest.placeOfBirth]: 'Tempat Lahir',
  [Digest.program]: 'Program Studi',
  [Digest.province]: 'Provinsi',
  [Digest.school]: 'Institusi Pendidikan',
  [Digest.studentIdNumber]: 'Nomor Induk Pelajar',
  [Digest.studentStatus]: 'Status Pelajar',
  [Digest.subDistrict]: 'Kecamatan',
  [Digest.totalDueForUpcomingTerm]: 'Total Due for Upcoming Term',
  [Digest.totalDueFromPreviousTerm]: 'Total Due from Previous Term',
  [Digest.course]: 'Jurusan',
  [Digest.dateDue]: 'Tanggal Jatuh Tempo',
  [Digest.daysInArrears]: 'Jumlah Hari Tunggakan Pembayaran',
  [Digest.guardianEmail]: 'Alamat Email Wali',
  [Digest.schoolRemainingBalance]: 'Tunggakan Semester Sebelumnya',
  [Digest.year]: 'Semester',
}

export const LITE_COPY: CopyRecord<Lite> = {
  [Lite.product]: 'DanacitaPay Lite+',
  [Lite.productDesc]:
    'Sediakan opsi pembayaran tambahan di portal kampus Anda dengan widget yang mudah dipasang dan pembayaran bulanan Danacita yang mudah diakses oleh pelajar.',
  [Lite.subheading]:
    'Selamat datang! Di halaman ini Anda dapat menemukan tools untuk mengintegrasi portal kampus Anda dengan Danacita.',
  [Lite.bannerDesc]:
    'Banner DanacitaPay sangat cocok jika Anda menginginkan widget horizontal yang ukurannya sesuai dengan interface / halaman / portal Anda untuk menampilkan Danacita sebagai opsi pembayaran.',
  [Lite.instalation]: 'Instalasi',
  [Lite.buttonDesc]:
    'Tombol DanacitaPay ini sangat cocok jika Anda menginginkan widget yang terlihat simple dan compact untuk menampilkan Danacita sebagai opsi pembayaran.',
  [Lite.landingDesc]:
    'Tombol DanacitaPay ini sangat cocok jika Anda menginginkan widget yang terlihat simple dan compact untuk menampilkan Danacita sebagai opsi pembayaran.',
  [Lite.note]:
    'Catatan: Potongan kode untuk kampus sudah di-kustomisasi oleh Tim Danacita. Pastikan Anda menggunakan potongan kode yang telah kami berikan.',
  [Lite.copy]: 'Salin Kode',
  [Lite.selectSchool]: 'Pilih Sekolah',
  [Lite.selectInterface]: 'Pilih Interface',
  [Lite.selectSize]: 'Pilih Ukuran',
  [Lite.instalationStep1]:
    '1. Salin dan tempel potongan kode ke file HTML halaman pembayaran pada portal kampus Anda.',
  [Lite.instalationStep2]:
    '2. Tunggu sebentar untuk melihat script kami berjalan di portal Anda.',
  [Lite.blankTitle]:
    'Kampus yang Anda pilih saat ini bukan mitra DanacitaPay Lite+',
  [Lite.blankSubtitle]:
    'Jika Anda tertarik untuk menginstall widget DanacitaPay Lite+ sebagai opsi pembayaran di website kampus Anda, silakan hubungi tim Partnership kami.',
}
